
#app{
  background:#fff;
}

.nav-list .nav-title[data-v-7ad837c7]{
  height: 36px;
  line-height: 36px;
  width: 200px;
  background-color: rgb(255, 64, 64);
  text-align: center;
  color:#fff;
  position: relative;
  font-size: 14px;
}
.nav-list .nav-title:hover .nav-list-list[data-v-7ad837c7]{
  display: block;
}
.nav-list-list[data-v-7ad837c7]{
  background:rgba(0,0,0,0.7);
  position: absolute;
  width:200px;
  display: none;
  z-index: 1;
}
.nav-list-list li[data-v-7ad837c7]{
  height:50px;
  line-height: 50px;
}
.box[data-v-7ad837c7] {
  width: 100%;
  height: 100px;
  background-color: #fff;
  border-bottom:1px solid #d4d5d6;
}
.nav[data-v-7ad837c7] {
  margin: 0 auto;
  width: 1200px;
  height:100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
.nav ul[data-v-7ad837c7] {
  list-style: none;
}
.nav .first[data-v-7ad837c7] {
  font-size: 12px;
  line-height: 55px;
  margin-right: 15px;
}
.nav a[data-v-7ad837c7] {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.nav a[data-v-7ad837c7]:hover{
  color:#ff4040;
}
.logo[data-v-7ad837c7]{
  margin-top:8px;
  position: absolute;
  left:13px;
  cursor: pointer;
}
.top[data-v-7ad837c7]{
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}
.top a[data-v-7ad837c7]{
  color:#000;
}
.top a[data-v-7ad837c7]:hover{
  color:#ff4040;
}
.top > li[data-v-7ad837c7]{
  float:left;
  padding:0 25px;
  margin:0;
  font-size:17px;
  height:36px;
  line-height: 36px;
}
.top > li[data-v-7ad837c7]:first-child{
  padding-left:50px;
}
.container[data-v-7ad837c7] {
  width: auto;
  position: absolute;
  height:36px;
  margin-top:64px;
  line-height: 36px;
}
.first[data-v-7ad837c7] {
  color: #000;
}
.first a[data-v-7ad837c7]:first-child {
  padding-left: 3px;
  border-left: none;
}
.first-list a[data-v-7ad837c7]:first-child {
  padding-left: 3px;
  border-left: none;
}
.city[data-v-7ad837c7] {
  padding: 10px 15px;
}
.city-item[data-v-7ad837c7] {
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  color:#fff;
}
.city-item[data-v-7ad837c7]:hover {
  color: #ff4040;
}
city_title[data-v-7ad837c7]{
  height:35px;
  text-align: center;
}
.person-icon[data-v-7ad837c7] {
  color: #d9534f;
  background-color:  #f0cdb2;
}
.username[data-v-7ad837c7] {
  color: #999999;
}
.username-p[data-v-7ad837c7] {
  cursor: pointer;
}
.my-page[data-v-7ad837c7] {
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.my-page a[data-v-7ad837c7] {
  margin: 0px;
  padding: 0px;
  border: none;
}
.my-info[data-v-7ad837c7] {
  padding: 5px;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.my-info[data-v-7ad837c7]:hover {
  -webkit-box-shadow: 0px 0px 5px #ccc;
          box-shadow: 0px 0px 5px #ccc;
  color:#ff4040;
}
.my-info i[data-v-7ad837c7] {
  font-size: 28px;
}
.my-info p[data-v-7ad837c7] {
  font-size: 12px;
}
.sign-out[data-v-7ad837c7] {
  padding: 5px;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.sign-out[data-v-7ad837c7]:hover {
  -webkit-box-shadow: 0px 0px 5px #ccc;
          box-shadow: 0px 0px 5px #ccc;
  color:#ff4040;
}
.sign-out i[data-v-7ad837c7] {
  font-size: 28px;
}
.sign-out p[data-v-7ad837c7] {
  font-size: 12px;
}
.detail[data-v-7ad837c7]{
  position: absolute;
  right:0;
  margin-top:46px;
  margin-right:20px;
}
.phone-btn[data-v-7ad837c7]{
  display: none;
  position: absolute;
  right:0;
  margin:15px 30px 0 0;
  padding:5px 10px
}
.phone-list-font[data-v-7ad837c7]{
  line-height: 30px;
  font-size:15px;
  padding:10px 16px;
  border-bottom:1px solid #ededed;
}
.phone-list-font a[data-v-7ad837c7]{
  color:#000;
}
.red a[data-v-7ad837c7]{
  color:#ff4040;
}
@media screen and (max-width: 1300px) {
.nav li[data-v-7ad837c7] {
    margin-right: 0px;
}
}
@media screen and (max-width: 1200px) {
.nav[data-v-7ad837c7] {
    width: 100%;
}
}
@media screen and (max-width: 800px) {
.container[data-v-7ad837c7]{
    display:none;
}
.nav li[data-v-7ad837c7]{
    font-size:10px;
}
.city_title[data-v-7ad837c7]{
    font-size:10px;
}
.phone-btn[data-v-7ad837c7]{
    display:block;
}
}

.icon[data-v-0b36738e] {
    width: 26px;
    height: 26px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    margin-bottom:4px;
    color:#e01314;
}
.Trialflow[data-v-0b36738e]{
    overflow: hidden;
}
.Trialflow> li[data-v-0b36738e]{
    float:left;
    width:19%;
    margin-right:1%;
    line-height: 70px;
    text-align: center;
    border-radius: 10px;
}
.ring[data-v-0b36738e]{
    width:50px;
    height:50px;
    font-size: 30px;
    border-radius: 50%;
    border:2px solid #e01314;
    float:left;
    line-height: 50px;
    margin:10px;
}
.anim[data-v-0b36738e]{
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    margin-top: -30px;
}
.anim > li[data-v-0b36738e]:first-child{
    opacity: 0;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}
#con1 li[data-v-0b36738e]{
    list-style: none;
    line-height: 30px;
    height: 30px;
}
.container[data-v-0b36738e]{
  background:url(./static/img/bg3.c06258d.jpg)no-repeat 50% -20vh;
}
.content[data-v-0b36738e] {
  width: 1200px;
  margin: 0px auto;
  position: relative;
}
  /*****************************商品专栏开始*****************************/
.item-class[data-v-0b36738e] {
  width: 100%;
  height:auto;
  margin-bottom:44px;
}
.item-class-head[data-v-0b36738e] {
  width: 100%;
  height: 88px;
  font-weight: lighter;
  line-height: 88px;
  border-top:1px solid #ccc;
}
.item-class-head ul[data-v-0b36738e] {
  list-style: none;
  float: right;
  margin-right: 30px;
}
.item-class-head a[data-v-0b36738e] {
  padding: 6px;
  margin-left: 15px;
  font-size: 12px;
  background-color: #6da6be;
  border: 1px solid #6da6be;
  text-decoration: none;
  color: #000;
}
.item-class-eat-head a[data-v-0b36738e] {
  background-color: #eeb955;
  border: 1px solid #eeb955;
}
.item-class-head a[data-v-0b36738e]:hover {
  border: 1px solid #fff;
}
.item-class-head li[data-v-0b36738e] {
  float: left;
}
.item-class-title[data-v-0b36738e] {
  font-size: 25px;
  color: #000;
  margin-left: 15px;
  height:auto;
}
.item-class-content[data-v-0b36738e] {
  padding:0 15px;
  /*background-color: #cff;*/
}
.item-class-content[data-v-0b36738e]:nth-child(odd) {
  border: none;
}
.item-content-top[data-v-0b36738e] {
  width: 100%;
  height: 260px;
}
.item-big-img[data-v-0b36738e] {
  width: 180px;
  height: 260px;
  overflow: hidden;
  float: left;
}
.item-big-img img[data-v-0b36738e] {
  margin-left: 0px;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.item-big-img:hover img[data-v-0b36738e] {
  margin-left: -15px;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.item-four-detail img[data-v-0b36738e] {
  margin-left: 0px;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.item-four-detail:hover img[data-v-0b36738e] {
  margin-left: -6px;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.item-four-detail-img img[data-v-0b36738e] {
  margin-left: 5px;
  width: 90px;
}
.item-content-bottom-img img[data-v-0b36738e] {
  margin-left: 0px;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.item-content-bottom-img:hover img[data-v-0b36738e] {
  margin-left: -15px;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
  /*******************************中奖名单*******************************/
.winnerList[data-v-0b36738e]{
    height:180px;
    overflow: hidden
}
#con1 > li[data-v-0b36738e]{
    font-size:14px;
}
  /*****************************商品专栏结束*****************************/
@media screen and (max-width: 1200px) {
.content[data-v-0b36738e]{
      width:100%;
}
}
ul li[data-v-0b36738e] {
    margin: 0;
    padding: 0;
    list-style: none;
}
.tab-tilte[data-v-0b36738e]{
    width:100%;
    overflow: hidden;
}
.tab-tilte li[data-v-0b36738e]{
    float: left;
    width: 120px;
    font-weight: bold;
    padding:0;
    text-align: center;
    cursor: pointer;
    font-size: 19px;
}
@media screen and (max-width: 500px) {
.Trialflow> li[data-v-0b36738e]{
      font-size: 12px;
}
}

.top[data-v-923a3184]{
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.top a[data-v-923a3184]{
  color:#000;
}
.top a[data-v-923a3184]:hover{
  color:#ff4040;
}
.container[data-v-923a3184] {
  margin: 0px auto;
  padding:30px 0;
  width: 460px;
}

.Notice[data-v-e3d08906]{
  width:33%;
  height:260px;
  float:left;
  margin-left:2%;
  border:1px solid #ccc;
  padding:0px 20px;
  background:#fff;
}
.NoticeOther[data-v-e3d08906]{
  width:33%;
  height:160px;
  float:left;
  margin-left:2%;
  margin-top:10px;
  border:1px solid #ccc;
  cursor: pointer;
  overflow: hidden;
}
.Notice-height[data-v-e3d08906]{
  height:auto;
}
.background[data-v-e3d08906]{
  background: url(./static/img/dui.941e3ba.jpg)no-repeat;
  width:100%;
  height:180px;
  background-position: center;
  background-size: contain;
}
.Notice-title[data-v-e3d08906]{
  font-size:16px;
  border-bottom:1px solid #ebebeb;
  padding:10px 0;
}
.Notice-list[data-v-e3d08906]{
  font-weight: 300;
  height:33.3333px;
  line-height: 33.3333px;
}
.Notice-none[data-v-e3d08906]{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Notice-list[data-v-e3d08906]:hover{
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.Notice-list > p > a[data-v-e3d08906]{
  color:#000;
  padding-left:7px;
  position: relative;
}
.Notice-list > p > a[data-v-e3d08906]::after{
  content:'';
  display:block;
  /*开始时候下划线的宽度为0*/
  width:1.5px;
  height:1.5px;
  position:absolute;
  left:0;
  bottom:7.65px;
  background:#ff4040;
  /*这里我们设定所有改变都有动画效果，可以自己指定样式才有动画效果*/
  -webkit-transition:all 0.3s;
  transition:all 0.3s;
  /*通过transform的缩放scale来让初始时x轴为0*/
  -webkit-transform: scale3d(3,2,1);
          transform: scale3d(3,2,1);
  /*将坐标原点移到元素的中间，以原点为中心进行缩放*/
  -webkit-transform-origin:50% 50%;
          transform-origin:50% 50%;
}
.Notice-list:hover > p > a[data-v-e3d08906]::after{
  color:#ff4040;
  -webkit-transform:scale3d(3,10,1);
          transform:scale3d(3,10,1);
}
.Slide[data-v-e3d08906]{
  width:65%;
  height:430px;
  float:left;
  -webkit-box-shadow: 0 0 8px #ccc;
          box-shadow: 0 0 8px #ccc;
}
.Slide_img[data-v-e3d08906]{
  -o-object-fit:cover;
     object-fit:cover;
  height:430px;
}

/*大的导航信息，包含导航，幻灯片等*/
.nav-body[data-v-e3d08906] {
  width: 1200px;
  height: 430px;
  padding-top:44px;
  margin: 0px auto;
  margin-bottom:88px;
}
.nav-side[data-v-e3d08906] {
  width: 200px;
  height: 100%;
  padding: 0px;
  color: #fff;
  float: left;
  background-color: #b2bec3;
  -webkit-box-shadow: 0 0 11px #ccc;
          box-shadow: 0 0 11px #ccc
}
.nav-side ul[data-v-e3d08906] {
  width: 100%;
  padding: 0px;
  padding-top: 15px;
  list-style: none;
}
.nav-side li[data-v-e3d08906] {
  padding: 7.5px;
  padding-left: 15px;
  font-size: 14px;
  line-height: 18px;
}
.nav-side li[data-v-e3d08906]:hover {
  background: #999395;
}
.nav-side-item[data-v-e3d08906]:hover {
  cursor: pointer;
  color: #c81623;
}

/*导航图片*/
.nav-show-img[data-v-e3d08906] {
  margin-top: 10px;
  float: left;
}
.nav-show-img[data-v-e3d08906]:nth-child(2) {
  margin-left: 12px;
}

/*显示商品*/
.content[data-v-e3d08906] {
  width: 100%;
}

/*显示商品详细信息*/
.detail-item-panel[data-v-e3d08906] {
  width: 815px;
  height: 485px;
  background-color: #fff;
  position: absolute;
  top: 168px;
  left: 389px;
  z-index: 999;
}
.nav-detail-item[data-v-e3d08906] {
  margin-left: 26px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #eee;
}
.nav-detail-item span[data-v-e3d08906] {
  padding: 6px;
  padding-left: 12px;
  margin-left: 15px;
  font-size: 12px;
  background-color: #6e6568;
}
.nav-detail-item span[data-v-e3d08906]:hover {
  margin-left: 15px;
  background-color: #f44336;
}
.detail-item-panel ul[data-v-e3d08906] {
  list-style: none;
}
.detail-item-panel li[data-v-e3d08906] {
  line-height: 38px;
  margin-left: 40px;
}
.detail-item-title[data-v-e3d08906] {
  padding-right: 6px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #555555;
}
.detail-item-title[data-v-e3d08906]:hover {
  color: #d9534f;
}
.detail-item-row a[data-v-e3d08906] {
  color: #555555;
}
.detail-item[data-v-e3d08906]{
  font-size: 14px;
  padding-left: 12px;
  padding-right: 8px;
  cursor: pointer;
  border-left: 1px solid #ccc;
}
.detail-item[data-v-e3d08906]:hover {
  color: #d9534f;
}
@media(max-width:1200px ) {
.nav-body[data-v-e3d08906] {
      width: 100%;
}
}
@media(max-width:750px ) {
.Slide[data-v-e3d08906]{
      width:100%;
      height:260px;
}
.Slide_img[data-v-e3d08906]{
      background-position: center;
      background-size:cover;
      height:260px;
}
.nav-body[data-v-e3d08906] {
      width: 100%;
      height: 100%;
      padding-top:44px;
      margin: 0px auto;
      margin-bottom:50px;
}
}
@media(max-width:750px ) {
.Notice[data-v-e3d08906]{
      width:100%;
      height:230px;
      float:left;
      margin-left:0%;
      border:1px solid #ccc;
      padding:0px 20px;
      background:#fff;
      margin-top:20px;
}
.NoticeOther[data-v-e3d08906]{
      width:100%;
      height:120px;
      float:left;
      margin-left:0%;
      margin-top:10px;
      border:1px solid #ccc;
}
}





























.container[data-v-3da8f03c] {
  margin: 15px auto;
  width: 1200px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.container-left[data-v-3da8f03c]{
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.container-right[data-v-3da8f03c]{
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.Lo-container[data-v-3da8f03c]{
  width:400px;
  right:0;
  padding:15px;
  -webkit-box-shadow: 15px 15px 3px #888888;
          box-shadow: 15px 15px 3px #888888;
  background-color:#fff
}
.Lo-title[data-v-3da8f03c]{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin:20px 0
}
.deng[data-v-3da8f03c]{
  font-size:18px;
  text-align: center;
  margin:10px 0;
}
.input-width[data-v-3da8f03c]{
  width:300px;
}
.denglist[data-v-3da8f03c]{
  font-weight: bold;
  color:gray;
}
.dengS[data-v-3da8f03c]{
  color:gray;
}
@media(max-width:1200px ) {
.container[data-v-3da8f03c]{
    width:80%;
}
}
@media(max-width:767px ) {
.container[data-v-3da8f03c]{
    width:100%;
}
.container-left[data-v-3da8f03c]{
    display:none;
}
.container-right[data-v-3da8f03c]{
    width: 300px;
    margin: 0 auto;
}
.Lo-container[data-v-3da8f03c]{
    width:300px;
    margin:0 auto;
    position: initial;
    padding:10px;
    -webkit-box-shadow: 15px 15px 3px #888888;
            box-shadow: 15px 15px 3px #888888;
    background-color:#fff
}
.input-width[data-v-3da8f03c]{
    width:110%;
}
}

.container[data-v-46dbb8a4] {
  margin: 15px auto;
  width: 1200px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.container-left[data-v-46dbb8a4]{
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.container-right[data-v-46dbb8a4]{
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.Lo-container[data-v-46dbb8a4]{
  width:400px;
  right:0;
  padding:15px;
  -webkit-box-shadow: 15px 15px 3px #888888;
          box-shadow: 15px 15px 3px #888888;
  background-color:#fff;
}
.Lo-title[data-v-46dbb8a4]{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin:20px 0
}
.input-width[data-v-46dbb8a4]{
  width:300px;
}
.deng[data-v-46dbb8a4]{
  font-size:18px;
  text-align: center;
  margin:10px 0;
}
.denglist[data-v-46dbb8a4]{
  font-weight: bold;
  color:gray;
}
.dengS[data-v-46dbb8a4]{
  color:gray;
}
@media(max-width:1200px ) {
.container[data-v-46dbb8a4]{
    width:80%;
}
}
@media(max-width:767px ) {
.container[data-v-46dbb8a4]{
    width:100%;
}
.container-left[data-v-46dbb8a4]{
    display:none;
}
.container-right[data-v-46dbb8a4]{
    width: 300px;
    margin: 0 auto;
}
.Lo-container[data-v-46dbb8a4]{
    width:300px;
    margin:0 auto;
    position: initial;
    padding:10px;
    -webkit-box-shadow: 15px 15px 3px #888888;
            box-shadow: 15px 15px 3px #888888;
    background-color:#fff
}
.input-width[data-v-46dbb8a4]{
    width:110%;
}
}

.FormItem[data-v-6833d3d2] {
  margin-top: 20px;
}

/*tab*/
ul li[data-v-6833d3d2] {
  margin: 0;
  padding: 0;
  list-style: none;
}
.container[data-v-6833d3d2] {
  margin: 0 auto;
  width: 800px;
  min-height: 80vh;
  position: relative;
  margin-top: 100px;
}
.tab-content[data-v-6833d3d2] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
}
.red[data-v-6833d3d2] {
  color: red;
  font-weight: bold;
}
@media screen and (max-width: 500px) {
.container[data-v-6833d3d2] {
    width: 100%;
    padding: 30px 0px;
}
}
.drag[data-v-6833d3d2] {
  position: relative;
  background-color: #e8e8e8;
  width: 100%;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
.handler[data-v-6833d3d2] {
  width: 40px;
  height: 34px;
  border: 1px solid #ccc;
  cursor: move;
}
.handler_bg[data-v-6833d3d2] {
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==") no-repeat center;
}
.handler_ok_bg[data-v-6833d3d2] {
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==") no-repeat center;
}
.drag_bg[data-v-6833d3d2] {
  background-color: #7ac23c;
  height: 34px;
  width: 0px;
}
.drag_text[data-v-6833d3d2] {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
}
.code-button[data-v-6833d3d2]{
  height:42px;
  padding:0 20px;
  position: absolute;
  top:0px;
  right:0px;
}

.ivu-input-large {
  height: 42px !important;
}
.ivu-form-item-label {
  padding: 15px 12px 15px 0 !important;
}

.RS-bg[data-v-b326e58c]{
  width:1200px;
  text-align: center;
  margin:0px auto;
}
.prize-bg[data-v-b326e58c]{
  background: url(./static/img/prize-bg1.b6a9af3.png)no-repeat;
  background-position: center;
  background-size: contain;
}
.RS-list[data-v-b326e58c]{
  width:40%;
  height:auto;
  border:3px solid #ff4040;
  margin:5%;
  padding:15px;
  background-color:rgb(255,255,255,0.8);
  border-radius: 30px;
  z-index: 5;
}
.RS-listtitle[data-v-b326e58c]{
  font-size:25px;
  font-weight: lighter;
  margin:0 auto;
  padding:5px 0;
  position: relative;
}
.RS-listtitle[data-v-b326e58c]::after{
  content:'';
  display:block;
  /*开始时候下划线的宽度为0*/
  width:0;
  height:2px;
  position:absolute;
  left:0;
  bottom:-3px;
  background:#ff4040;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.RS-listtitle[data-v-b326e58c]:hover::after{
  width:100%;
}
.RS-listT[data-v-b326e58c]{
  text-align: left;
  height:90px;
  line-height: 90px;
}
.RS-listNR[data-v-b326e58c]{
  font-size:17.5px;
  text-align: left;
  font-weight: lighter;
  position: relative;
}
.RS-listNR[data-v-b326e58c]::after{
  content:'';
  display:block;
  /*开始时候下划线的宽度为0*/
  width:0;
  height:1.5px;
  position:absolute;
  left:0;
  bottom:-3px;
  background:#ff4040;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out
}
.RS-listT:hover .RS-listNR[data-v-b326e58c]::after{
  width:100%;
}
.RS-bt[data-v-b326e58c]{
  width:200px;
  border-radius:3;
  background-color:#f1f2f4;
  margin-bottom:20px;
}
.RS-bt[data-v-b326e58c]:hover{
  border:1px solid #ff4040;
  color:#fff;
  background-color:#ff4040;
}
@media(max-width:1213px ) {
.RS-bg[data-v-b326e58c]{
    width:80%;
}
.RS-list[data-v-b326e58c]{
    width:80%;
    height:auto;
    margin:10px 10%;
    background-color:rgb(255,255,255,0.8);
    border-radius: 30px
}
}
@media(max-width:544px ) {
.RS-listtitle[data-v-b326e58c]{
    font-size:20px;
    font-weight: lighter;
    margin-bottom:10px
}
.RS-listNR[data-v-b326e58c]{
    font-size:10px;
    margin:5% 0px;
    text-align: left;
    font-weight: lighter;
}
.RS-bt[data-v-b326e58c]{
    width:100px;
    background-color:#f1f2f4;
}
.RS-listT[data-v-b326e58c]{
    height:50px;
    line-height: 50px;
}
}

.shi[data-v-06530a18]{
  font-size: 25px;
  color:#ff4040;
  padding:10px 0px;
  border-bottom:2px solid #ff4040;
}
.FormTitle[data-v-06530a18]{
  font-size: 20px;
  line-height:40px;
  margin-top:10px
}
.FormItem[data-v-06530a18]{
  margin-top:20px
}

/*tab*/
ul li[data-v-06530a18] {
  margin: 0;
  padding: 0;
  list-style: none;
}
.container[data-v-06530a18] {
  margin:0 auto;
  width: 490px;
  height: auto;
  padding:30px 20px;
}
.red[data-v-06530a18]{
  color:red;
  font-weight:bold;
}
@media screen and (max-width: 500px) {
.container[data-v-06530a18] {
    width: 100%;
    padding:30px 0px;
}
}
.code-button[data-v-06530a18]{
  height:42px;
  line-height: 42px;
  padding:0 20px;
  position: absolute;
  right:90px;
}

.ivu-input-large{
  height:42px !important;
}

.container[data-v-210397ab] {
    margin: 80px 0;
    height: 485px;
    background-color: #fff;
}
.Lo-container[data-v-210397ab]{
    width:400px;
    height:500px;
    margin:0 auto;
    border:2px solid #f39c12;
    padding:15px
}
.input-width[data-v-210397ab]{
    width:300px;
}
.Lo-title[data-v-210397ab]{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin:35px 0
}
.ivu-form-item[data-v-210397ab]{
    margin-bottom:45px;
}
@media(max-width:767px ) {
.Lo-container[data-v-210397ab]{
    width:300px;
    margin:0 auto;
    border:2px solid #f39c12;
    padding:10px
}
.input-width[data-v-210397ab]{
    width:100%;
}
}
@media screen and (max-width: 500px) {
.container[data-v-210397ab] {
      width: 100%;
      padding:30px 0px;
}
}
.drag[data-v-210397ab]{
    position: relative;
    background-color: #e8e8e8;
    width: 100%;
    height: 34px;
    line-height: 34px;
    text-align: center;
}
.handler[data-v-210397ab]{
    width: 40px;
    height: 34px;
    border: 1px solid #ccc;
    cursor: move;
}
.handler_bg[data-v-210397ab]{
    background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==") no-repeat center;
}
.handler_ok_bg[data-v-210397ab]{
    background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==") no-repeat center;
}
.drag_bg[data-v-210397ab]{
    background-color: #7ac23c;
    height: 34px;
    width: 0px;
}
.drag_text[data-v-210397ab]{
    position: absolute;
    top: 0px;
    width: 100%;text-align: center;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -o-user-select:none;
    -ms-user-select:none;
}

.title-top[data-v-1d792868]{
  font-size:19px;
  font-weight: bold;
  margin:0 0 10px 0;
}
.title-list[data-v-1d792868]{
  font-size:17px;
  margin-top:10px;
  font-weight: 100;
}
.title-gund[data-v-1d792868]{
margin:4px 0;
}
.city-list[data-v-1d792868]{
  width:1200px;
  margin:0 auto;
  padding:20px;
  z-index: 2;
}
.red[data-v-1d792868]{
  color:red
}
.prize1[data-v-1d792868]{
  background:url("http://47.103.79.45/group1/M00/00/02/rBO0zF3vFWyAQZ1QAABSY1opXjo537.png")no-repeat;
  background-position: center;
  background-size: cover;
  width:200px;
  height:285px;
  cursor: pointer;
  margin:10px auto;
}
.prize-bg[data-v-1d792868]{
  background: url(./static/img/pzbg.541d634.png)no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 1200px) {
.city-list[data-v-1d792868]{
    width:100%;
    margin:0 auto;
}
.prize-bg[data-v-1d792868]{
    width:100%;
}
}
@media screen and (max-width: 730px) {
.title-top[data-v-1d792868]{
    font-size:14px;
}
.title-list[data-v-1d792868]{
    font-size:14px;
}
.prize[data-v-1d792868]{
    border:2px solid #8395a7;
    font-size:9px;
    height:140px;
    font-weight: bold;
}
.prize-title[data-v-1d792868]{
    margin:20px 0;
}
}

.container[data-v-199bc706] {
  margin:15px auto;
  width: 1200px;
}
.text-danger[data-v-199bc706] {
  color: #A94442;
}
.seckill-price[data-v-199bc706]{
  margin-right: 5px;
  font-size: 25px;
  font-weight: bold;
}
.goods-box[data-v-199bc706] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/* ---------------侧边广告栏开始------------------- */
.item-as-title span[data-v-199bc706]:first-child{
  margin-left: 20px;
}
.item-as-title span[data-v-199bc706]:last-child{
  float: right;
  margin-right: 15px;
  font-size: 10px;
  color: #ccc;
}
.item-as-price span[data-v-199bc706]{
  font-size: 18px;
}
.item-as-selled span[data-v-199bc706]{
  color: #005AA0;
}
/* ---------------侧边广告栏结束------------------- */
/* ---------------商品栏开始------------------- */
.goods-list-box[data-v-199bc706] {
  width:100%;
}
.goods-list-tool[data-v-199bc706]{
  width: 100%;
  height: 38px;
  border:1px solid #ededed;
}
.goods-list-tool ul[data-v-199bc706]{
  padding-left: 15px;
  list-style: none;
}
.goods-list-tool li[data-v-199bc706]{
  cursor: pointer;
  float: left;
}
.goods-list-tool span[data-v-199bc706]{
  padding: 5px 8px;
  line-height: 36px;
  background-color: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
/*.goods-list-tool span:hover{
  background-color:#ff4040;
  color:#fff;
}*/
.goods-list-tool span[data-v-199bc706]{
  background-color:#ff4040;
  color:#fff;
}
.goods-show-info[data-v-199bc706]{
  width: 18%;
  padding: 10px;
  border: 1px solid #f1f2f4;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 6px;
  margin:8px 1%;
  top:5px;
}
.goods-show-info[data-v-199bc706]:hover{
  -webkit-box-shadow: 0px 0px 15px #ccc;
          box-shadow: 0px 0px 15px #ccc;
  top:-5px;
}
.goods-show-price[data-v-199bc706]{
  margin-top: 6px;
}
.goods-show-detail[data-v-199bc706]{
  font-size: 12px;
  margin: 6px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}
.goods-show-p[data-v-199bc706]{
  font-size:15px;
  margin:5px 0;
  white-space: nowrap;
}
.goods-show-num[data-v-199bc706]{
  font-size: 12px;
  margin-bottom: 6px;
  color: #009688;
}
.goods-show-num span[data-v-199bc706]{
  color: #005AA0;
  font-weight: bold;
}
.goods-show-seller[data-v-199bc706]{
  font-size: 12px;
  color:#E4393C;
}
.goods-page[data-v-199bc706] {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.goods-show-img[data-v-199bc706]{
  text-align: center;
}
/* ---------------商品栏结束------------------- */
@media screen and (max-width: 1200px) {
.container[data-v-199bc706] {
    width: 100%;
}
}
@media screen and (max-width: 830px) {
.goods-show-info[data-v-199bc706]{
    width: 45%;
    padding: 5px;
    border: 1px solid #f1f2f4;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 6px;
    margin:8px 2.5%;
}
.goods-show-p[data-v-199bc706]{
    font-size:12px;
}
.seckill-price[data-v-199bc706]{
    margin-right: 5px;
    font-size: 15px;
    font-weight: bold;
}
}

.search-nav[data-v-10ea754e]{
  width: 100%;
  height: 64px;
  border-bottom: 2px solid #ff4040;
}
.search-nav-container[data-v-10ea754e]{
  width: 1200px;
  height: 64px;
  margin: 0px auto;
}
.search-nav-container-90[data-v-10ea754e]{
  width: 90%;
}
.search-nav-container ul[data-v-10ea754e]{
  margin: 0px;
  padding-left: 0px;
  list-style: none;
}
.search-nav-container li[data-v-10ea754e]{
  line-height: 64px;
  font-size: 23px;
  /*font-weight: bold;*/
  float: left;
  color:#ff4040;
  margin-left:8px;
}
.search-nav-container a[data-v-10ea754e]{
  color: #C81623;
  text-decoration: none;
}
.search-nav-container li[data-v-10ea754e]:first-child{
  padding: 0px 38px;
  background:#f39c12;
  color: #fff;
}
@media screen and (max-width: 1200px) {
.search-nav-container[data-v-10ea754e]{
    width: 100%;
}
}
@media screen and (max-width: 700px) {
.search-nav-container li[data-v-10ea754e]{
    line-height: 64px;
    font-size: 19px;
    /*font-weight: bold;*/
    float: left;
    margin-left:8px;
}
}

.item-class-show[data-v-b707953a] {
  margin: 15px auto;
  width: 100%;
}
.item-class-group[data-v-b707953a] {
  height: 45px;
}
.item-class-name[data-v-b707953a] {
  padding-left: 15px;
  line-height: 44px;
  color: #fff;
  font-weight: bold;
  background-color: #ff4040;
}
.item-class-name[data-v-b707953a]:first-child {
  line-height: 45px;
  border-top:1.5px solid;
}
.item-class-select[data-v-b707953a]{
  width:87.5%;
  height:auto;
  line-height: 47px;
}
.item-class-select a[data-v-b707953a] {
   margin-left: 15px;
   width: 160px;
   color: #000;
   line-height: 45px;
   cursor: pointer;
}
.item-class-select a[data-v-b707953a]:hover {
  color: red;
}
@media screen and (max-width: 700px) {
.item-class-name[data-v-b707953a] {
    padding-left:0;
    text-align: center;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
    font-size:5px;
}
}

.shop-item-path[data-v-03ec29b4] {
  height: 38px;
  background-color: rgb(236, 235, 235);
  line-height: 38px;
  color: #2c2c2c;
}
.shop-nav-container[data-v-03ec29b4] {
  margin: 0px auto;
  width: 80%;
}






















































































































































































































































/******************商品图片及购买详情开始******************/
.item-detail-show[data-v-7f316d1e] {
  width: 1200px;
  margin: 15px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #fff;
}
.item-detail-left[data-v-7f316d1e] {
  width: 350px;
  margin-right: 30px;
}
.item-detail-big-img[data-v-7f316d1e] {
  width: 350px;
  height: 350px;
  -webkit-box-shadow: 0px 0px 8px #ccc;
          box-shadow: 0px 0px 8px #ccc;
  cursor: pointer;
}
.item-remind[data-v-7f316d1e]{
  font-size:18px;
}
.item-detail-big-img img[data-v-7f316d1e] {
  width: 100%;
}
.item-detail-img-row[data-v-7f316d1e] {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.item-detail-img-small[data-v-7f316d1e] {
  width: 68px;
  height: 68px;
  -webkit-box-shadow: 0px 0px 8px #ccc;
          box-shadow: 0px 0px 8px #ccc;
  cursor: pointer;
}
.item-detail-img-small img[data-v-7f316d1e] {
  width: 100%;
}
.item-m[data-v-7f316d1e]{
  margin:7px 0;
}
/*商品选购详情*/
.item-detail-right[data-v-7f316d1e] {
}
.item-detail-title p[data-v-7f316d1e] {
  color: #666;
  font-size: 20px;
  width: 600px;
}
.item-detail-express[data-v-7f316d1e] {
  font-size: 14px;
  padding: 2px 3px;
  border-radius: 3px;
  background-color: #e4393c;
  color: #fff;
}
/*商品标签*/
.item-detail-tag[data-v-7f316d1e] {
  font-size: 18px;
  color: #000;
  margin:10px 0
}
/*价格详情等*/
.item-detail-price-row[data-v-7f316d1e] {
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #f3f3f3;
}
.item-price-left[data-v-7f316d1e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.item-price-title[data-v-7f316d1e] {
  color: #999999;
  font-size: 14px;
  margin-right: 15px;
}
.item-price-row[data-v-7f316d1e] {
  margin: 5px 0px;
}
.item-price[data-v-7f316d1e] {
  color: #e4393c;
  font-size: 23px;
  cursor: pointer;
}
.item-price-full-cut[data-v-7f316d1e] {
  margin-right: 5px;
  padding: 3px;
  color: #e4393c;
  font-size: 12px;
  background-color: #ffdedf;
  border: 1px dotted #e4393c;
  cursor: pointer;
}
.item-remarks-sum[data-v-7f316d1e] {
  padding-left: 8px;
  border-left: 1px solid #ccc;
}
.item-remarks-sum p[data-v-7f316d1e] {
  color: #999999;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
}
.item-remarks-num[data-v-7f316d1e] {
  line-height: 18px;
  color: #005eb7;
}
.item-select[data-v-7f316d1e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 15px;
}
.item-select-title[data-v-7f316d1e] {
  color: #999999;
  font-size: 14px;
  margin-right: 15px;
}
.item-select-column[data-v-7f316d1e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.item-select-row[data-v-7f316d1e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-bottom: 8px;
}
.item-select-box[data-v-7f316d1e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.item-select-img[data-v-7f316d1e] {
  width: 36px;
}
.item-select-box[data-v-7f316d1e] {
  padding: 5px;
  margin-right: 8px;
  background-color: #f7f7f7;
  border: 0.5px solid #ccc;
  cursor: pointer;
}
.item-select-box[data-v-7f316d1e]:hover {
  border: 0.5px solid #e3393c;
}
.item-select-box-active[data-v-7f316d1e] {
  border: 0.5px solid #e3393c;
}
.item-select-img img[data-v-7f316d1e] {
  width: 100%;
}
.item-select-intro p[data-v-7f316d1e] {
  margin: 0px;
  padding: 5px;
}
.item-select-class[data-v-7f316d1e] {
  padding: 5px;
  margin-right: 8px;
  background-color: #f7f7f7;
  border: 0.5px solid #ccc;
  cursor: pointer;
}
.item-select-class[data-v-7f316d1e]:hover {
  border: 0.5px solid #e3393c;
}
.add-buy-car-box[data-v-7f316d1e] {
  width: 100%;
  margin-top: 15px;
  border-top: 1px dotted #ccc;
}
.add-buy-car[data-v-7f316d1e] {
  margin-top: 15px;
}
/******************商品图片及购买详情结束******************/
@media(max-width:1200px ) {
.item-detail-show[data-v-7f316d1e] {
    width: 100%;
}
}
@media(max-width:700px ) {
.item-detail-show[data-v-7f316d1e] {
    width: 1200px;
    margin: 15px auto;
    display: initial;
    -webkit-box-orient: initial;
    -webkit-box-direction: initial;
        -ms-flex-direction: initial;
            flex-direction: initial;
    background-color: #fff;
}
.item-detail-left[data-v-7f316d1e] {
    width: 100%;
}
.item-detail-big-img[data-v-7f316d1e] {
    margin:0 auto;
}
.item-detail-title p[data-v-7f316d1e]{
    margin-top:20px;
}
.item-detail-right[data-v-7f316d1e]{
    padding:10px;
    width:100vw;
}
}
@media(max-width:450px ) {
.item-detail-title p[data-v-7f316d1e]{
    font-size:15px;
    font-weight: bold;
}
.item-detail-big-img[data-v-7f316d1e] {
    width: 320px;
    height: 320px;
    margin:0 auto;
}
.item-price-title[data-v-7f316d1e]{
    font-size: 12px;
    margin-right: 0px;
}
.item-remind[data-v-7f316d1e]{
    font-size: 14px;
}
}

table[data-v-77506dc9]
  {
    border-collapse:collapse;
    width:100%;
    height:50px;
    border-bottom:1px solid #ccc;
}
table td[data-v-77506dc9]{
    color:#5e5e5e;
    width:50%;
    padding:10px;
}
ul[data-v-77506dc9]{
    overflow: hidden;
}
.tab-tilte[data-v-77506dc9]{
    border-radius: 5px;
    height:35px;
    line-height: 35px;
    margin-bottom:10px;
}
.tab-tilte li[data-v-77506dc9]{
    float: left;
    width: 25%;
    text-align: center;
    background-color:#f4f4f4;
    cursor: pointer;
    list-style-type: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
  /* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-77506dc9]{
    background-color: #ff4040;
    color: #fff;
}
  /***************商品详情介绍和推荐侧边栏开始***************/
.item-intro-show[data-v-77506dc9]{
  width: 1200px;
  margin: 15px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #fff;
}
.item-recommend-img img[data-v-77506dc9]{
  width: 100%;
}
.item-intro-detail[data-v-77506dc9]{
  width:100%;
}
.item-intro-nav[data-v-77506dc9]{
  width: 100%;
  height: 38px;
}
.item-intro-img img[data-v-77506dc9]{
  width: 100%;
}
  /************* 商品参数 *************/
.item-param-container[data-v-77506dc9] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding:10px;
}
.remarks-title[data-v-77506dc9] {
  padding-left: 15px;
  height: 36px;
  font-size: 16px;
  font-weight: bolder;
  line-height: 36px;
  color: #666666;
  background-color: #F7F7F7;
}
.remarks-bar span[data-v-77506dc9] {
  margin-right: 15px;
}
.remarks-page[data-v-77506dc9] {
  margin: 15px;
  text-align: center;
  -webkit-box-pack:end;
      -ms-flex-pack:end;
          justify-content:flex-end;
}
  /***************商品详情介绍和推荐侧边栏结束***************/
@media(max-width:1200px ) {
.item-intro-show[data-v-77506dc9]{
      width: 1200px;
      margin: 15px auto;
      display: initial;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      background-color: #fff;
}
}








































































































































































































































































/* 改变便签页样式 */
.ivu-tabs-nav-scroll{
  background:#f7f7f7;
}
.ivu-tabs-ink-bar {
  background-color: #ff4040 !important;
}
.item-tabs > .ivu-tabs > .ivu-tabs-bar .ivu-tabs-tab{
  border-radius: 0px;
  color: #000;
  height: 38px;
}
.item-tabs > .ivu-tabs > .ivu-tabs-bar .ivu-tabs-tab-active{
  color: #ff4040;
}
.item-tabs > .ivu-tabs > .ivu-tabs-bar .ivu-tabs-tab-active:before{
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  color: #fff;
  background: #F7F7F7;
  position: absolute;
  top: 0;
  left: 0;
}
.ivu-rate-star-full:before, .ivu-rate-star-half .ivu-rate-star-content:before {
  color: #E4393C;
}






























































































/****************************加入购物车页面开始*****************************/
.add-info-box-container[data-v-57e53a8a]{
  width: 100%;
  background-color: #F5F5F5;
}
.add-info-box[data-v-57e53a8a]{
  width: 90%;
  margin: 0px auto;
  padding: 15px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.add-info-detail[data-v-57e53a8a]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.add-info-title[data-v-57e53a8a]{
  font-size: 25px;
  color: #71b247;
}
.add-info-box-row[data-v-57e53a8a]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 15px;
}
.add-info-img[data-v-57e53a8a]{
  width: 56px;
  height: 56px;
  margin-right: 15px;
}
.add-info-img img[data-v-57e53a8a]{
  width: 100%;
}
.add-info-intro[data-v-57e53a8a]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.add-info-intro-detail[data-v-57e53a8a]{
  font-size: 12px;
  color: #999999;
}
.car-btn-group[data-v-57e53a8a]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
/*按钮*/
.btn-car[data-v-57e53a8a]{
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 0px;
  border:none;
  margin-right: 15px;
}
.btn-car-to-detail[data-v-57e53a8a]{
  background-color: #fff;
  color: #e4393c;
  border:1px solid #fff;
}
.btn-car-to-detail[data-v-57e53a8a]:hover{
border:1px solid #e4393c;
}
.btn-car-to-pay[data-v-57e53a8a]{
  background-color: #e4393c;
  color: #fff;
  border:1px solid #e4393c;
}
.btn-car-to-pay[data-v-57e53a8a]:hover{
  background-color: #c91623;
  border:1px solid #c91623;
}
/*其他用户购买*/
.other-user-buy-box[data-v-57e53a8a]{
  width: 90%;
  margin: 0px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.other-user-buy-title[data-v-57e53a8a]{
  margin-top: 25px;
  font-size: 14px;
  color: #666;
  font-weight: bold;
}
.other-user-buy-row[data-v-57e53a8a]{
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.other-user-buy-item-box[data-v-57e53a8a]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.other-user-buy-item-img[data-v-57e53a8a]{
  width: 96px;
  height: 96px;
}
.other-user-buy-item-img img[data-v-57e53a8a]{
  width: 100%;
}
.other-buy-detail-box[data-v-57e53a8a]{
  width: 160px;
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.other-buy-title[data-v-57e53a8a]{
  font-size: 12px;
}
.other-buy-title a[data-v-57e53a8a]{
  color: #2c2c2c;
  text-decoration: none;
}
.other-buy-price[data-v-57e53a8a]{
  font-size: 12px;
  font-weight: bold;
  color: #E4393C;
}
.other-buy-btn[data-v-57e53a8a]{
  padding: 3px 10px;
  color: #E4393C;
  font-size: 12px;
  border: 1px solid #E4393C;
  border-radius: 0px;
  background-color: #fff;
}
.other-buy-btn[data-v-57e53a8a]:hover{
  color: #c91623;
  border: 1px solid #c91623;
}
/****************************加入购物车页面结束*****************************/

.bg[data-v-c2d53802]{
  background:url("/static/img/bg.jpg")no-repeat top;
}
.container[data-v-c2d53802]{
  width:1000px;
  margin:0 auto;
  height:auto;
}
.process[data-v-c2d53802]{
  width:100%;
  padding-top:490px;
}
.p-con[data-v-c2d53802]{
  background: #fff;
  border-radius: 5px;
  padding:50px 0px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.p-con-inv[data-v-c2d53802]{
  background: #fff;
  border-radius: 5px;
  padding:50px;
  width: 100%;
  position: relative;
}
.p-title[data-v-c2d53802]{
  position: absolute;
  left:50%;
  top:-50px;
  background: url("/static/img/title.png")no-repeat center;
  background-size: cover;
  width: 300px;
  height: 85px;
  margin-left:-150px;
  font-size: 16px;
  font-weight: bold;
  line-height: 85px;
  text-align: center;
  color: #f2f2f2;
}
.p-lg[data-v-c2d53802]{
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  text-align: center;
}
.p-ov[data-v-c2d53802]{
  height:206px;
  overflow: hidden;
}
.p-height[data-v-c2d53802]{
  height:90px;
}
.p-con-nor[data-v-c2d53802]{
  background: #fff;
  border-radius: 5px;
  padding:60px 0px;
  width: 100%;
  position: relative;
}
.p-cen[data-v-c2d53802]{
  text-align: center;
  width: 100%;
}
.p-pad[data-v-c2d53802]{
  padding:0 60px;
}
.p-pad-list[data-v-c2d53802]{
  margin-top:20px;
  font-size: 14px;
  color:#a19187;
}
.btn[data-v-c2d53802]{
  width:200px;
  padding:10px;
  font-size: 14px;
  background:#2d8cf0;
  color:#fff;
  border:none;
  border-radius: 5px;
  margin:0 auto;
  display: block;
  margin-top:30px;
  cursor: pointer;
}
.p-link-input[data-v-c2d53802]{
  width:calc(100% - 120px);
  border:none;
  background:#fff6f3;
  height:40px;
  color: #432410;
  font-size: 14px;
  padding:0 10px;
  float:left;
}
.p-input[data-v-c2d53802]{
  width:100%;
  margin-bottom:30px;
  overflow: hidden;
}
.p-link-btn[data-v-c2d53802]{
  width:100px;
  float:right;
  height:40px;
  border: 0;
  background: #ff6150;
  color:#fff;
  font-size:16px;
  border-radius: 2px;
  cursor: pointer;
}
.p-qr-link[data-v-c2d53802]{
  width:100px;
  height:40px;
  border: 0;
  background: #ff6150;
  color:#fff;
  font-size:16px;
  border-radius: 2px;
  cursor: pointer;
  margin-left:10px;
}
.p-QR[data-v-c2d53802]{
  width:150px;
  height:150px;
  display: block;
  margin-top:10px;
}
.p-textarea[data-v-c2d53802]{
  resize: none;
  width:100%;
  height:150px;
  outline: none;
  border:2px solid #ff6150;
  font-size: 18px;
  color:#444;
  padding:10px;
}
.p-next-btn[data-v-c2d53802]{
  width:100px;
  height:40px;
  background:#fff;
  border:1px solid #ff4040;
  border-radius: 2px;
  color:#ff4040;
  font-size:14px;
  margin-left:10px;
  cursor: pointer;
}
.p-flex-space[data-v-c2d53802]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.p-margin-top[data-v-c2d53802]{
  margin-top:10px;
}
.p-flex-end[data-v-c2d53802]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
input[data-v-c2d53802],button[data-v-c2d53802]{
  outline:none;
}

.Home-Navigation[data-v-d8f10882]{
float:right;
margin-top:15px;
font-size:20px;
padding:5px;
height:35px;
line-height: 15px;
}
.side-bar a[data-v-d8f10882]{
  color: #232323;
}
.layout-footer-center[data-v-d8f10882] {
  padding: 0px 15px;
  padding-bottom: 15px;
  text-align: center;
}
.bg-color[data-v-d8f10882]{
  background-color:#f1f2f4;
}
.list[data-v-d8f10882]{
  width:1200px;
  margin:0 auto
}
@media screen and (max-width: 1300px) {
.side-bar[data-v-d8f10882]{
  display: none;
}
.ivu-layout[data-v-d8f10882]{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    margin:0;
    padding:0 7%;
}
}
@media screen and (min-width: 1301px) {
.Home-Navigation[data-v-d8f10882] {
    display:none;
}
}
@media screen and (max-width: 1200px) {
.list[data-v-d8f10882]{
    width:100%;
    margin:0 auto
}
}
@media screen and (max-width: 850px) {
.ivu-layout[data-v-d8f10882]{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    margin:0;
    padding:0;
}
}
.detailed[data-v-d8f10882]{
    font-size:15px;
    text-align: center;
    height:48px;
    line-height: 48px;
    cursor: pointer;
}
.ivu-layout-content[data-v-d8f10882]{
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.content[data-v-d8f10882]{
    min-height: 746px;
}

.Head-pc[data-v-ebe5df7a]{
    width:220px;
    float:left;
    text-align: center;
    margin-top: 30px;
    MARGIN-RIGHT:15PX;
}
.Progress[data-v-ebe5df7a]{
    margin-left:201px
}
.list-line[data-v-ebe5df7a]{
    line-height: 2.5;
}
.list-line1[data-v-ebe5df7a]{
    line-height: 2;
}
.home-list-title[data-v-ebe5df7a]{
    background:#dfe4ea;
    font-size:17px;
    padding:9px;
}
dl[data-v-ebe5df7a]{
    overflow: hidden;
}
dl > dd[data-v-ebe5df7a]{
    float:left;
    width:20%;
    text-align: center;
}
.home-list-nr[data-v-ebe5df7a]{
    font-size:15px;
    padding:9px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.home-list-nr[data-v-ebe5df7a]:hover{
    background:#f1f2f6;
}
.Presentation[data-v-ebe5df7a]{
    margin-top:10px;
    margin-bottom:10px;
    border:1px solid #f1f2f4;
}
.home-title[data-v-ebe5df7a]{
  margin-top:10px;
  margin-bottom:10px;
}
.home-fontsize[data-v-ebe5df7a]{
  font-size:30px;
  margin-right: 8px;
}
.Reminder-title[data-v-ebe5df7a]{
  font-size:18px;
  color:#fff;
  font-weight: bold;
  margin:10px 0;
  background-color:#ffc364;
  border-radius: 7px;
  padding-left:10px
}
.Reminder-content[data-v-ebe5df7a]{
  font-size:16px;
}
.red[data-v-ebe5df7a]{
  color:red;
}
.blue[data-v-ebe5df7a]{
  color:blue;
}
ul[data-v-ebe5df7a]{
  list-style-type: none;
}
.yellow[data-v-ebe5df7a]{
  color:yellow
}
.orange[data-v-ebe5df7a]{
  color:orange;
}
.developments[data-v-ebe5df7a]{
  border-right:4px solid #e74c3c;
  background-color:#f1f2f4;
  margin-top:10px;
  font-size:15px;
  padding-left:10px;
}
.developments-title[data-v-ebe5df7a]{
  font-weight: bold;
}
.Notice[data-v-ebe5df7a]{
  width:100%;
  height:200px;
  border:1px solid #f1f2f4;
  margin-top:15px;
  overflow: hidden;
}
.NoticeTitle[data-v-ebe5df7a]{
  width:100%;
  font-size:18px;
  height:40px;
  line-height: 40px;
  padding:0 20px;
  font-weight: bold;
}
.NoticeRight[data-v-ebe5df7a]{
  float: right;
  font-size:13px;
  font-weight: 500
}
.NoticeContent[data-v-ebe5df7a]{
  font-size:15px;
  line-height: 30px;
  height:30px;
}

  /*头像的样式*/
.avatar-uploader .el-upload[data-v-ebe5df7a] {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload[data-v-ebe5df7a]:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon[data-v-ebe5df7a] {
    font-size: 28px;
    color: #8c939d;
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.avatar[data-v-ebe5df7a] {
    width: 200px;
    height: 200px;
    display: block;
}
.NoticeContent > ul > li[data-v-ebe5df7a]{
    height:25px;
    line-height: 25px;
}
@media screen and (max-width: 1300px) {
.Head-pc[data-v-ebe5df7a]{
      display:none;
}
.Progress[data-v-ebe5df7a]{
      margin-left:0;
}
.Reminder-content[data-v-ebe5df7a]{
      font-size:12px;
}
.home-list-title[data-v-ebe5df7a]{
      font-size:14px;
      padding:5px;
}
.home-list-nr[data-v-ebe5df7a]{
      font-size:14px;
      padding:5px;
}
.Reminder-title[data-v-ebe5df7a]{
      font-size:14px;
}
}
.goods-show-img[data-v-ebe5df7a]{
    text-align: center;
    padding:0 12px
}
.goods-show-price[data-v-ebe5df7a]{
    margin-top: 6px;
    padding-left:12px;
}
.goods-show-p[data-v-ebe5df7a]{
    font-size:15px;
    margin:5px 0;
    white-space: nowrap;
}
.hand[data-v-ebe5df7a] {
    cursor: pointer;
}
.content[data-v-ebe5df7a] {
    margin: 15px;
    background-color: #fff;
    padding:15px;
    -webkit-box-shadow: 0 0 5px #ccc;
            box-shadow: 0 0 5px #ccc;
}





















































































































































/* image */
.img-circle {
  border-radius: 50%;
}
.img-lg {
  width: 170px;
  height: 170px;
}
.avatar-upload-preview {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 4px #ccc;
          box-shadow: 0 0 4px #ccc;
  overflow: hidden;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}


.content[data-v-93b098bc]{
  margin:15px;
  background-color:#fff;
  padding: 15px;
}
.report-title[data-v-93b098bc]{
  width:100%;
  height:30px;
  margin-bottom:20px
}
.report-title-left[data-v-93b098bc]{
  float:left;
  font-size:17px;
  font-weight: bold;
  line-height: 30px;
}
.report-title-right[data-v-93b098bc]{
  float:right;
  font-size:17px;
  line-height: 30px;
}
.red[data-v-93b098bc]{
  color:red
}
.list-surface[data-v-93b098bc]{
  width:100%;
  height:30px;
}
.list-surface ul[data-v-93b098bc]{
  line-height: 10px;
}
.list-surface ul li[data-v-93b098bc]{
  text-align: center;
  float:left;
  width:16%;
  list-style: none;
  font-size:17px;
  font-weight: bold;
}
.surface-nr[data-v-93b098bc]{
  margin-top:30px
}
.surface-list[data-v-93b098bc]{
  float:left;
  width:16%;
  text-align: center;
  font-size:16px
}
.demo-tabs-style1 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab[data-v-93b098bc] {
  border-color: transparent;
  font-size: 20px !important;
}
@media screen and (max-width: 800px) {
.list-surface ul li[data-v-93b098bc]{
    text-align: center;
    float:left;
    width:20%;
    list-style: none;
    font-size:10px;
    font-weight: bold;
}
.report-title-left[data-v-93b098bc]{
    font-size:10px;
}
.report-title-right[data-v-93b098bc]{
    font-size:10px;
}
.surface-list[data-v-93b098bc]{
    font-size:10px;
}
}

.content[data-v-c9e4613e]{
    margin:15px;
    background-color:#fff;
    padding: 15px;
}
.report-title[data-v-c9e4613e]{
    width:100%;
    height:30px;
    margin-bottom:20px;
    line-height: 30px;
}
.auxiliary-top[data-v-c9e4613e]{
    margin-top:30px
}
.red[data-v-c9e4613e]{
    color:red
}
ul li[data-v-c9e4613e] {
    margin: 0;
    padding: 0;
    list-style: none;
}
.tab-tilte[data-v-c9e4613e]{
    width:100%;
}
.tab-tilte li[data-v-c9e4613e]{
    float: left;
    width: 12%;
    padding:0;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
}

  /* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-c9e4613e]{
    font-weight: bold;
}
.tab-title-right[data-v-c9e4613e]{
    float:right;
    font-size: 17px;
}
.auxiliary[data-v-c9e4613e]{
  float:left;
  width:20%;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
.auxiliary-nr[data-v-c9e4613e]{
    float:left;
    width:20%;
    text-align: center;
    font-size: 15px;
}
.auxiliary-gq[data-v-c9e4613e]{
    font-size:17px;
    font-weight: bold;
    text-align: center;
}
.auxiliary-g1-nr[data-v-c9e4613e]{
    font-size:15px;
    text-align: center;
    height:40px;
    line-height: 40px;
}
@media screen and (max-width: 800px) {
.tab-tilte li[data-v-c9e4613e]{
      font-size: 10px;
}
.tab-title-right[data-v-c9e4613e]{
      font-size: 10px;
}
.auxiliary[data-v-c9e4613e]{
      font-size: 10px;
}
.auxiliary-gq[data-v-c9e4613e]{
      font-size:10px;
}
.auxiliary-nr[data-v-c9e4613e]{
      font-size: 10px;
}
.auxiliary-g1-nr[data-v-c9e4613e]{
      font-size:10px;
}
}

.content[data-v-397489fe]{
  margin:15px;
  background-color:#fff;
  padding: 15px;
}
.red[data-v-397489fe]{
  color: red;
}
ul li[data-v-397489fe] {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tab-tilte[data-v-397489fe]{
  width: 50%;
  height:45px;
  margin:0 auto;
}
.tab-tilte li[data-v-397489fe]{
  float: left;
  width: 50%;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-size:17px
}

/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-397489fe]{
  color: #000;
  font-weight: bold;
}
.tab-content .frame[data-v-397489fe]{
  width: 50%;
  height:100%;
  margin:0 auto;
}
.Title[data-v-397489fe]{
  font-size:16px;
  color:#3498db;
  height:40px;
}
.warning[data-v-397489fe]{
  font-size:15px;
  color:orange;
  margin:10px
}
.title-top[data-v-397489fe]{
  margin-top:30px;
}
.top[data-v-397489fe]{
  font-size:17px;
  font-weight: bold;
  text-align: center;
}
.title-nr[data-v-397489fe]{
  font-size:17px;
  text-align: center;
}
.feedback-Reply[data-v-397489fe]{
  padding:40px 20px;
  margin-top:30px;
  background-color:#f1f2f4;
}
@media screen and (max-width: 800px) {
.tab-content .frame[data-v-397489fe]{
    width: 100%;
}
.tab-tilte[data-v-397489fe]{
    width: 100%;
    height:45px;
    margin:0 auto;
}
.top[data-v-397489fe]{
    font-size:11px;
}
.title-nr[data-v-397489fe]{
    font-size:11px;
}
}
.hide .el-upload--picture-card[data-v-397489fe] {
  display: none;
}
.report-title[data-v-397489fe]{
  width:100%;
  height:30px;
  margin-bottom:20px
}
.report-title-left[data-v-397489fe]{
  float:left;
  font-size:17px;
  font-weight: bold;
  line-height: 30px;
}









































































.content[data-v-c29f66fe]{
    margin:15px;
    background-color:#fff;
    padding: 15px;
}
ul li[data-v-c29f66fe] {
    margin: 0;
    padding: 0;
    list-style: none;
}
.tab-tilte[data-v-c29f66fe]{
    width: 100%;
    height:44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.tab-tilte li[data-v-c29f66fe]{
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}
  /* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-c29f66fe]{
    color: #000;
    font-weight: bold;
}
.input-width[data-v-c29f66fe]{
  width:70%
}
.select_small[data-v-c29f66fe]{
  width:100%
}
.table-center[data-v-c29f66fe]{
    width:70%;
    margin:30px auto;
}
@media screen and (max-width: 800px) {
.tab-tilte li[data-v-c29f66fe]{
      padding: 10px 0;
      text-align: center;
      cursor: pointer;
      font-size: 10px;
}
.table-center[data-v-c29f66fe]{
      width:100%;
      margin:0px;
}
}

.ddd[data-v-14098842]{
  height:40px;
  line-height: 40px;
  border-radius: 5px;
  padding:0 10px;
  border:1px solid #fff;
}
.ddd[data-v-14098842]:hover{
  border:1px solid #ff4040;
  background-color:#ff404022;
}
.cursor[data-v-14098842]{
  cursor: pointer;
  font-size: 18px;
  font-weight: 100;
}

.tab-tilte li[data-v-f7ebb17a]{
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}
/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-f7ebb17a]{
  color: #000;
  font-weight: bold;
}
.input-width[data-v-f7ebb17a]{
  width:70%
}
.table-center[data-v-f7ebb17a]{
  width:50%;
  margin:30px auto;
}
@media screen and (max-width: 800px) {
.tab-tilte li[data-v-f7ebb17a]{
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    font-size: 10px;
}
.table-center[data-v-f7ebb17a]{
    width:100%;
    margin:0px;
}
}





























































































































































































































































































































.tab-tilte li[data-v-1714b0da]{
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}
/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-1714b0da]{
  color: #000;
  font-weight: bold;
}
.table-center[data-v-1714b0da]{
  width:50%;
  margin:30px auto;
}
.position[data-v-1714b0da]{
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  margin:auto;
  width:-webkit-max-content;
  width:-moz-max-content;
  width:max-content;
  height:-webkit-max-content;
  height:-moz-max-content;
  height:max-content;
  font-size: 30px;
  color:#ff0000;
}
.blue[data-v-1714b0da]{
  color:#2b85e4;
}
@media screen and (max-width: 800px) {
.tab-tilte li[data-v-1714b0da]{
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    font-size: 10px;
}
.table-center[data-v-1714b0da]{
    width:100%;
    margin:0px;
}
}

.tab-tilte li[data-v-81341bea]{
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}
/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-81341bea]{
  color: #000;
  font-weight: bold;
}
.table-center[data-v-81341bea]{
  width:50%;
  margin:30px auto;
}
@media screen and (max-width: 800px) {
.tab-tilte li[data-v-81341bea]{
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    font-size: 10px;
}
.table-center[data-v-81341bea]{
    width:100%;
    margin:0px;
}
}

.testred[data-v-24510397]{
  color:red;
  font-size:15px;
  line-height: 25px;
}
ul li[data-v-24510397] {
  margin: 0;
  padding: 0;
  list-style: none;
}
#app[data-v-24510397] {
  width: 100%;
  height:100%;
  margin: 0 auto;
}
.tab-tilte[data-v-24510397]{
  width: 100%;
  height:44px
}
.tab-tilte li[data-v-24510397]{
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}
/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-24510397]{
  color: #000;
  font-weight: bold;
}
.input-width[data-v-24510397]{
  width:70%
}
.select_small[data-v-24510397]{
  width:100%
}
.table-center[data-v-24510397]{
  width:50%;
  margin:30px auto;
}
@media screen and (max-width: 800px) {
.tab-tilte li[data-v-24510397]{
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    font-size: 10px;
}
.table-center[data-v-24510397]{
    width:100%;
    margin:0px;
}
}

.tab-tilte li[data-v-ab0b2146]{
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}
/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active[data-v-ab0b2146]{
  color: #000;
  font-weight: bold;
}
.table-center[data-v-ab0b2146]{
  width:50%;
  margin:30px auto;
}
@media screen and (max-width: 800px) {
.tab-tilte li[data-v-ab0b2146]{
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    font-size: 10px;
}
.table-center[data-v-ab0b2146]{
    width:100%;
    margin:0px;
}
}

.content[data-v-8eb615ea]{
  margin:15px;
  background-color:#fff;
  padding: 15px;
}
.title[data-v-8eb615ea]{
  font-size:16px;
  text-align: center;
}
.list[data-v-8eb615ea]{
  text-align: center;
  margin:5px 0;
}
.list > div[data-v-8eb615ea]{
  /*display: table-row;
  vertical-align: middle;
  line-height: 68px;
  text-align: center;*/
  text-align: center;
}
.list > .Content[data-v-8eb615ea]{
  font-size:16px;
  vertical-align: middle;
  text-align: center;
}
/*.list > .state{
  font-size:16px;
  !*display: table-row;
  vertical-align: middle;
  text-align: center;*!
  text-align: center;
}*/
.btn[data-v-8eb615ea]{
  line-height: 100px;
  text-align: center;
}
.time[data-v-8eb615ea]{
  margin-bottom:10px;
}
.title-list[data-v-8eb615ea]{
  cursor: pointer;
}
.title-list[data-v-8eb615ea]:active{
  font-weight: bold;
}
.Subtitle[data-v-8eb615ea]{
    text-align: center;
    font-size:16px;
    font-weight: bold;
    margin:20px 0
}
.time-title[data-v-8eb615ea]{
    color:gray;
    font-size: 16px;
}
.Content[data-v-8eb615ea]{
    font-weight: bold;
    font-size:16px;
    width:230px;
    height:30px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.Contant-item[data-v-8eb615ea]{
    height:30px;
    line-height: 30px;
    font-size:16px;
    width:100%;
}
.block[data-v-8eb615ea]{
  height:150px;
  margin-bottom:10px;
  border-bottom:1px dashed #ccc;
  padding:5px 0
}
@media screen and (max-width: 800px) {
.phone-none[data-v-8eb615ea]{
    display:none;
}
.title-none[data-v-8eb615ea]{
    display:none;
}
.Subtitle[data-v-8eb615ea]{
    text-align: center;
    font-size:12px;
    font-weight: bold;
    margin:10px 0
}
.time-title[data-v-8eb615ea]{
    color:gray;
    font-size: 12px;
}
.Content[data-v-8eb615ea]{
    font-weight: bold;
    font-size:12px;
    margin-bottom:10px
}
.Contant-item[data-v-8eb615ea]{
    font-size:12px;
    margin:5px 0
}
.block[data-v-8eb615ea]{
    height:150px;
    margin-bottom:10px;
    border-bottom:1px dashed #ccc;
    padding:5px 0
}
}
@media screen and (min-width: 800px) {
.phone-none[data-v-8eb615ea]{
    display: block;
}
}
.blackFont[data-v-8eb615ea]{
  font-weight:bold;
  color: red;
}

.ddd[data-v-0087fa58]{
    height:40px;
    line-height: 40px;
    border-radius: 5px;
    padding:0 10px;
    border:1px solid #fff;
}
.ddd[data-v-0087fa58]:hover{
    border:1px solid #ff4040;
    background-color:#ff404022;
}
.cursor[data-v-0087fa58]{
  cursor: pointer;
  font-size: 18px;
  font-weight: 100;
}
































































































.content[data-v-e2628364]{
    margin:15px;
    background-color:#fff;
    padding: 15px;
}
.top-right[data-v-e2628364]{
  text-align: right;
  width:100%;
  height:30px;
  line-height: 30px;
  font-size:17px;
}
.title[data-v-e2628364]{
  text-align: center;
  font-size:17px;
  font-weight: bold;
}
.title-nr[data-v-e2628364]{
  text-align: center;
  font-size:15px;
}
.top[data-v-e2628364]{
  margin-top:30px
}
.bottom-fen[data-v-e2628364]{
  text-align: center;
  margin-top:30px;
}
@media screen and (max-width: 800px) {
.top-right[data-v-e2628364]{
    font-size:10px;
}
.title[data-v-e2628364]{
    font-size:10px;
}
.title-nr[data-v-e2628364]{
    font-size:10px;
}
}

.content[data-v-d7c021de]{
    margin:15px;
    background-color:#fff;
    padding: 15px;
}
.title-top[data-v-d7c021de]{
  text-align: right;
  font-size:17px;
}
.title[data-v-d7c021de]{
  font-size:17px;
  font-weight: bold;
  text-align: center;
}
.mar-top[data-v-d7c021de]{
    margin-top:30px;
}
.deta-nr[data-v-d7c021de]{
    font-size:17px;
    text-align: center;
}
.fen[data-v-d7c021de]{
    text-align: center;
    margin-top:30px
}
@media screen and (max-width: 800px) {
.title-top[data-v-d7c021de]{
    font-size:10px;
}
.title[data-v-d7c021de]{
    font-size:10px;
}
.deta-nr[data-v-d7c021de]{
    font-size:10px;
}
}

.full[data-v-7a803381]{
    background-color:#f1f2f4;
}
.content[data-v-7a803381] {
    margin: 15px;
    background-color: #fff;
    padding: 15px;
}
.feedback-Reply[data-v-7a803381]{
    padding:40px 20px;
    margin-top:60px;
    background-color:#f1f2f4;
}
.title-top[data-v-7a803381]{
  font-size:17px;
  font-weight: bold;
  text-align: center;
  margin-top:30px;
}
.center-nr[data-v-7a803381]{
    font-size:17px;
    text-align: center;
    margin-top:30px
}
.right[data-v-7a803381]{
    text-align: right;
    font-size:17px;
}
.red[data-v-7a803381]{
    color:red;
}
.fen[data-v-7a803381]{
    text-align: center;
    margin-top:30px;
}
@media screen and (max-width: 800px) {
.right[data-v-7a803381]{
    text-align: right;
    font-size:10px;
}
.title-top[data-v-7a803381]{
    font-size:10px;
}
.center-nr[data-v-7a803381]{
    font-size:10px;
}
}

.content[data-v-5c06ed77]{
  margin:15px;
  background-color:#fff;
  padding: 15px;
}
.title-top[data-v-5c06ed77]{
  margin-top:30px;
}
.top[data-v-5c06ed77]{
  font-size:17px;
  font-weight: bold;
  text-align: center;
}
.title-nr[data-v-5c06ed77]{
  font-size:17px;
  text-align: center;
}
.feedback-Reply[data-v-5c06ed77]{
  padding:40px 20px;
  margin-top:30px;
  background-color:#f1f2f4;
}
@media screen and (max-width: 800px) {
.tab-content .frame[data-v-5c06ed77]{
    width: 100%;
}
.tab-tilte[data-v-5c06ed77]{
    width: 100%;
    height:45px;
    margin:0 auto;
}
.top[data-v-5c06ed77]{
    font-size:11px;
}
.title-nr[data-v-5c06ed77]{
    font-size:11px;
}
}
.hide .el-upload--picture-card[data-v-5c06ed77] {
  display: none;
}
.report-title[data-v-5c06ed77]{
  width:100%;
  height:30px;
  margin-bottom:20px
}
.report-title-left[data-v-5c06ed77]{
  float:left;
  font-size:17px;
  font-weight: bold;
  line-height: 30px;
}

.content[data-v-8fbc57d2] {
  margin: 15px;
  background-color: #fff;
  padding:15px;
  -webkit-box-shadow: 0 0 5px #ccc;
          box-shadow: 0 0 5px #ccc;
}
.title[data-v-8fbc57d2]{
  font-size:16px;
  text-align: center;
  font-weight: bold;
  height:30px;
  line-height: 30px;
  top:5px;
}
.list > img[data-v-8fbc57d2]{
  height:100px;
  width:100px;
}
.list[data-v-8fbc57d2]{
  height:100px;
  text-align: center;
  font-size:15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.content[data-v-4aa5d738]{
  margin: 15px;
  background-color: #fff;
  padding: 15px;
}
.r-modular[data-v-4aa5d738]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
}
.r-cart[data-v-4aa5d738]{
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
          box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  padding:40px 20px;
  margin:10px;
  background:#fff;
  font-size: 16px;
}
.r-list-flex[data-v-4aa5d738]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin:15px 0;
}
.r-list[data-v-4aa5d738]{
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  text-align: center;
  font-size:15px;
  font-weight: bold;
}
.r-list-nr[data-v-4aa5d738]{
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  text-align: center;
  font-size:15px;
}

.text-danger[data-v-2a9c0c62] {
  color: #A94442;
}
.seckill-price[data-v-2a9c0c62]{
  margin-right: 5px;
  font-size: 25px;
  font-weight: bold;
}
.goods-container[data-v-2a9c0c62] {
  margin: 15px auto;
  width: 80%;
}
.goods-list[data-v-2a9c0c62] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.goods-show-info[data-v-2a9c0c62]{
  width: 240px;
  padding: 10px;
  margin: 15px 0px;
  border: 1px solid #fff;
  cursor: pointer;
}
.goods-show-info[data-v-2a9c0c62]:hover{
  border: 1px solid #ccc;
  -webkit-box-shadow: 0px 0px 15px #ccc;
          box-shadow: 0px 0px 15px #ccc;
}
.goods-show-price[data-v-2a9c0c62]{
  margin-top: 6px;
}
.goods-show-detail[data-v-2a9c0c62]{
  font-size: 12px;
  margin: 6px 0px;
}
.goods-show-num[data-v-2a9c0c62]{
  font-size: 12px;
  margin-bottom: 6px;
  color: #009688;
}
.goods-show-num span[data-v-2a9c0c62]{
  color: #005AA0;
  font-weight: bold;
}
.goods-show-seller[data-v-2a9c0c62]{
  font-size: 12px;
  color:#E4393C;
}
.goods-page[data-v-2a9c0c62] {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.contant[data-v-5218038e]{
    width:100%;
    height:80vh;
    position: relative;
}
.main[data-v-5218038e] {
    background:url(./static/img/404.51629c6.png)no-repeat center;
    background-size: contain;
    width:100%;
    height:80vh;
}
p[data-v-5218038e]{
  width:1000px;
  height:70px;
  font-size:30px;
  font-weight: bold;
  position: absolute;
  text-align: center;
  top:74vh;
}

.contant[data-v-47a2512a]{
    width:100%;
    height:80vh;
    position: relative;
}
.main[data-v-47a2512a] {
    background:url(./static/img/401.e1e97e4.png)no-repeat center;
    background-size: contain;
    width:100%;
    height:80vh;
}
p[data-v-47a2512a]{
  width:1000px;
  height:70px;
  font-size:30px;
  font-weight: bold;
  position: absolute;
  text-align: center;
  top:72vh;
}

.contant[data-v-3b565418]{
    width:100%;
    height:80vh;
    position: relative;
}
.main[data-v-3b565418] {
    background:url(./static/img/500.dafe1d8.png)no-repeat center;
    background-size: contain;
    width:100%;
    height:80vh;
}
p[data-v-3b565418]{
  width:1000px;
  height:70px;
  font-size:30px;
  font-weight: bold;
  position: absolute;
  text-align: center;
  top:74vh;
}

.body[data-v-2cccb2ac]{
  width:1200px;
  margin:0 auto;
}
.title[data-v-2cccb2ac]{
  font-size:25px;
  text-align: center;
  font-weight: bold;
  margin:50px 0;
}
p[data-v-2cccb2ac]{
    font-size:19px;
    height:35px;
    line-height: 35px;
    margin:25px 0;
}
.img[data-v-2cccb2ac]{
    width:50%;margin:40px 0;display: block;
}

.Navigation[data-v-7c915471]{
    margin:20px 0;
    border:1px solid #dcdcdc;
    overflow: hidden;
    min-height:80vh;
    background:#fcfcfc;
    position: relative;
}
.contant[data-v-7c915471]{
  width:100%;
  min-height: 70vh;
}
.content[data-v-7c915471]{
  width:1200px;
  height:auto;
  margin:0 auto;
}
ul[data-v-7c915471]{
  overflow: hidden;
}
li[data-v-7c915471]{
  list-style-type: none;
}
.prot-left[data-v-7c915471]{
  position: absolute;
  top:0;
  left:0;
  width:200px;
  height:auto;
}
.prot-left > li[data-v-7c915471]{
    height:40px;
    line-height: 40px;
    font-size:16px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.prot-left > li:hover > a[data-v-7c915471]{
  color:#ff4040;
  cursor: pointer;
}
.prot-right[data-v-7c915471]{
  float:right;
  width:1000px;
  height:auto;
  min-height:80vh;
  background-color:#fff;
  padding:15px;
}
.red[data-v-7c915471]{
    color: #ff4040;
}
@media screen and (max-width: 500px) {
.prot-left > li[data-v-7c915471]{
      font-size:13px;
}
.Navigation[data-v-7c915471]{
      margin:0;
      border:none;
      overflow: auto;
}
}
@media screen and (max-width: 1200px) {
.content[data-v-7c915471]{
      width:100%;
}
.prot-left[data-v-7c915471]{
      width:20%;
}
.prot-right[data-v-7c915471]{
      width:80%;
}
}

.title[data-v-4958a3c8]{
  text-align: center;
  font-size: 1.3rem;
  height:40px;
  line-height: 40px;
  margin-bottom:20px;
}
th[data-v-4958a3c8]{
  font-size:15px;
  padding:10px 15px;
}
td[data-v-4958a3c8]{
  height:20px;
  line-height: 20px;
  padding:10px 15px;
  font-size:15px;
}
a[data-v-4958a3c8]{
  color:#000;
}
a[data-v-4958a3c8]:hover{
  color:#ff4040;
}

ul[data-v-f1e78ea6]{
  overflow: hidden;
}
li[data-v-f1e78ea6]{
  list-style-type: none;
}
.title[data-v-f1e78ea6]{
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.nr[data-v-f1e78ea6]{
  font-size:16px;
  line-height: 30px;
  font-weight: 400;
}
.prot-left > li[data-v-f1e78ea6]{
  height:40px;
  line-height: 40px;
  font-size:16px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.prot-left > li[data-v-f1e78ea6]:hover{
  color:#ff4040;
  cursor: pointer;
}


p[data-v-05b9eb70]{
  text-indent:20px;
  line-height: 25px;
  margin-bottom:10px;
}

.text-color[data-v-a34058e8]{
  background-color:#f7ecb5;
}
p[data-v-a34058e8]{
  line-height: 25px;
}
.title[data-v-a34058e8]{
  color:#ff4040;
  font-weight: bold;
}

p[data-v-76434277]{
  font-size:16px;
  margin-bottom:25px;
}
span[data-v-76434277]{
  font-weight: bold;
}
.font[data-v-76434277]{
  font-size:16px;
  height:25px;
  line-height: 25px;
}






































































/*****************************底 部 开 始*****************************/
.footer[data-v-7ffdad53] {
  width: 100%;
  height: 90px;
}
.service-intro[data-v-7ffdad53] {
  width: 100%;
  border-top: 1px solid #2980b9;
}
.servece-type-info ul[data-v-7ffdad53] {
  list-style: none;
}
.servece-type-info li[data-v-7ffdad53] {
  font-size: 14px;
  cursor: pointer;
  line-height: 26px;
}
.servece-type-info li[data-v-7ffdad53]:first-child {
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
}
.friend-link[data-v-7ffdad53] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 30px;
  padding:22px 0 22px 0;
  margin: 0px auto;
  color: #666;
}
.friend-link-item[data-v-7ffdad53] {
  margin: 0px auto;
}
.friend-link-item ul[data-v-7ffdad53] {
  list-style: none;
}
.friend-link-item li[data-v-7ffdad53] {
  padding: 5px 0px;
  float: left;
}
.link-item[data-v-7ffdad53] {
  font-size:13px;
  padding: 0px 8px;
  cursor: pointer;
  border-right: 1px solid #ccc;
}
.link-last-item[data-v-7ffdad53] {
  border: none;
}
.copyright a[data-v-7ffdad53]{
  color: #232323;
  font-size: 20px;
}
.record[data-v-7ffdad53]{
  width:100%;
  text-align: center;
}
/*****************************底 部 结 束*****************************/
